<!-- 套票订单详情 -->

<template>
  <div class="assoc-detail-dialog">
    <w-dialog ref="dialogRef" title="套票订单详情" width="60%" top="4vh" :hideFooter="true">
      <!-- <div style="height: 740px;overflow: auto;"> -->
      <div class="basic-infor">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">套票订单</div>
              <div class="basic-infor-content">{{ inforDatas.ticket_name }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">套票类型</div>
              <div class="basic-infor-content">{{ inforDatas.ticket_type_name }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">所属景区</div>
              <div class="basic-infor-content">{{ inforDatas.scenic_name }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">订单号码</div>
              <div class="basic-infor-content">{{ inforDatas.sn }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">购买渠道</div>
              <div class="basic-infor-content">{{ inforDatas.source_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付方式</div>
              <div class="basic-infor-content">{{ inforDatas.payway_name }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">下单时间</div>
              <div class="basic-infor-content">{{ inforDatas.ctime }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">购买数量</div>
              <div class="basic-infor-content">{{ inforDatas.buy_num }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付金额</div>
              <div class="basic-infor-content">{{ inforDatas.pay_money }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付时间</div>
              <div class="basic-infor-content">{{ inforDatas.pay_time }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">退款数量</div>
              <div class="basic-infor-content">{{ inforDatas.refund_num }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">退款金额</div>
              <div class="basic-infor-content">{{ inforDatas.refund_money }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">优惠金额</div>
              <div class="basic-infor-content">{{ inforDatas.yh_money }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">代金券</div>
              <div class="basic-infor-content">{{ inforDatas.coupon_money }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付状态</div>
              <div class="status-text" :class="orderStatusColors(inforDatas.pay_status, 'pay')">
                {{ inforDatas.pay_status_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">业务状态</div>
              <div class="status-text" :class="orderStatusColors(inforDatas.work_status, 'work')">
                {{ inforDatas.work_status_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">销售模式</div>
              <div class="basic-infor-content">{{ inforDatas.sale_way_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">分销商</div>
              <div class="basic-infor-content">{{ inforDatas.agent_name }}</div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="flex">
              <div class="basic-infor-title">使用期限</div>
              <div class="basic-infor-content">{{ inforDatas.sdate }}至{{ inforDatas.edate }}</div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="flex">
              <div class="basic-infor-title">备注</div>
              <div class="basic-infor-content">{{ inforDatas.ot_remark }}</div>
            </div>
          </el-col>
        </el-row>
      </div>

      <w-title :title_name="'游客信息'"></w-title>
      <div class="download-operation">
        <div class="batch-upload ptb-16" @click="downloadTemp"
          v-if="authData.indexOf('h_yQqrHe8Tskvl1ivieXez90WLefuc') != -1 && inforDatas.is_download == '1'">
          下载导入模板
        </div>
        <el-upload v-if="authData.indexOf('h_fPseQ6oM7TlRhVFtUNIkXXZTbksy') != -1 && inforDatas.is_upload == '1'"
          class="batch-upload" action="" ref="upload" :limit="1" :file-list="fileList" :show-file-list="false"
          :http-request="customUpload" accept=".xls,.xlsx">
          <div class="batch-upload-text">批量导入</div>
        </el-upload>
      </div>
      <common-table ref="touristTableRef" :ispaging="false" :tableData="inforDatas.reservation"
        :columns="touristTableColumns" tableHeight="200">
        <template #pass_status="{ row }">
          <div class="status-text"
            :class="row.pass_status == '1' ? 'btn-red' : row.pass_status == '2' ? 'btn-green' : 'btn-blue'">
            {{ row.pass_status_text }}</div>
        </template>

        <!-- 编辑 -->
        <template #edit="{ row }">
          <template v-if="row.is_edit == '1' && authData.indexOf('h_9xlF7osS6A6HQXMa5YGYhgX6O6ZG') != -1">
            <el-button class="theme-font-btn" @click="openTouristDialog(row, 'edit')">编辑</el-button>
          </template>
        </template>

        <!-- 退款 -->
        <template #refund="{ row }">
          <template v-if="row.is_refund == '1' && authData.indexOf('h_ZLY1B2aJ1VwOgDzYYGXUFjNIh58k') != -1">
            <el-button class="black-font-btn" @click="openTouristDialog(row, 'refund')">退款</el-button>
          </template>
        </template>
      </common-table>

      <w-title :title_name="'景点分张明细'"></w-title>
      <common-table ref="attractiTableRef" :ispaging="false" :tableData="inforDatas.ledger_account"
        :columns="attractiTableColumns" tableHeight="200">
      </common-table>

      <w-title :title_name="'退款明细'"></w-title>
      <common-table ref="refundTableRef" :ispaging="false" :tableData="inforDatas.refund" :columns="refundTableColumns"
        tableHeight="200">
      </common-table>
      <!-- </div> -->
    </w-dialog>

    <!-- 编辑游客信息 -->
    <EditTouristDialog ref="editTouristDialogRef" @submit="handleEditTourist"></EditTouristDialog>

    <!-- 退款 -->
    <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>
  </div>
</template>

<script>
  import { ref, nextTick, computed, watch, } from "vue";
  import { HotelApi, } from "@/plugins/api.js";
  import { useStore } from "vuex";
  import { ElMessage } from "element-plus";
  import { orderStatusColors, } from "@/utils/common.js";
  import EditTouristDialog from "../../order/components/EditTouristDialog.vue";
  import RefundDialog from "../../order/components/RefundDialog.vue";
  import axios from "axios";
  import getBaseUrl from "@/plugins/baseUrl.js";

  export default {
    emits: ["submit"],
    components: {
      EditTouristDialog,
      RefundDialog,
    },
    setup(props, { emit }) {
      const baseUrl = getBaseUrl() ? getBaseUrl() : "";
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      const dialogRef = ref(null);  // 弹框对象
      const currentRow = ref('');  // 当前行数据
      const inforDatas = ref('');  // 详情数据
      const touristTableRef = ref(null);  // 游客信息对象
      const touristTableColumns = ref([  // 游客表格配置
        {
          prop: "name",
          label: "游客名",
        },
        {
          prop: "mobile",
          label: "手机号",
        },
        {
          prop: "idcard",
          label: "身份证号",
        },
        {
          prop: "pass_status", // 1: 未通行  2.通行中  3.已通行
          label: "通行状态",
          type: "customRender",
        },
        {
          type: "operation",
          prop: "",
          label: "操作",
          minWidth: 190,
          bottons: [
            {
              name: "编辑",
              action: "edit",
              type: "customRender",
            },
            {
              name: "退款",
              action: "refund",
              type: "customRender",
            },
          ],
        },
      ]);
      const attractiTableRef = ref(null);  // 景点分张明细对象
      const attractiTableColumns = ref([  // 景点分张明细表格配置
        {
          prop: "name",
          label: "景区名称",
        },
        {
          prop: "money",
          label: "分账金额",
        },
      ]);
      const refundTableRef = ref(null);  // 退款明细对象
      const refundTableColumns = ref([  // 退款明细表格配置
        {
          prop: "refund_sn",
          label: "退款单号",
        },
        {
          prop: "refund_money",
          label: "退款金额",
        },
        {
          prop: "refund_payway_name",
          label: "退款方式",
        },
        {
          prop: "refund_confirm_user",
          label: "退款确认人",
        },
        {
          prop: "refund_confirm_time",
          label: "退款时间",
        },
        {
          prop: "refund_reason",
          label: "退款原因",
        },
      ]);
      const editTouristDialogRef = ref(null);  // 编辑对象
      const initRefundRef = ref(null);  // 退款对象
      /**
         * 
         * 打开对话框
         * 
         * **/
      const openDialog = (row) => {
        currentRow.value = row;
        getDetails();
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 打开对话框加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 获取详情数据
       * 
       * **/
      const getDetails = () => {
        HotelApi.getAssocOrderDetails({ sn: currentRow.value.sn }).then((res) => {
          // console.log("详情", res);
          if (res.Code === 200) {
            inforDatas.value = res.Data;
            dialogRef.value.show();
            nextTick(() => {
              touristTableRef.value.tableLoad();
              attractiTableRef.value.tableLoad();
              refundTableRef.value.tableLoad();
            });
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      const refundPeopleId = ref('');  // 退款id
      /**
       * 
       * 游客信息-编辑、退款
       * 
       * **/
      const openTouristDialog = (row, type) => {
        if (type == 'edit') {
          // 编辑
          editTouristDialogRef.value.openDialog(row);
        } else {
          // 退款
          refundPeopleId.value = row.id;
          const lastMoney =
            +inforDatas.value.pay_money - +inforDatas.value.refund_money;
          initRefundRef.value.openDialog("发起退款", lastMoney);
        }
      }
      /**
       * 
       * 游客信息-编辑确定按钮
       * 
       * **/
      const handleEditTourist = (data) => {
        const parmas = {
          idcard: data.idcard,
          realname: data.name,
          mobile: data.mobile,
          otr_id: data.id,
        };
        // console.log("编辑", parmas)
        data.face_url ? (parmas["otr_face_url"] = data.face_url) : "";
        HotelApi.updateReservation(parmas).then((res) => {
          editTouristDialogRef.value.closeDialogLoading();
          if (res.Code === 200) {
            ElMessage.success("编辑成功！");
            getDetails();
            editTouristDialogRef.value.closeDialog();
            emit("submit");
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
       * 
       * 退款确定按钮
       * 
       * **/
      const handleInitRefund = (data) => {
        const parmas = {
          sn: currentRow.value.sn,
          refund_mode: 2,  // 退款模式 2-部分退款模式
          otr_id: refundPeopleId.value,
          ...data,
        };
        // console.log("退款参数", parmas);
        HotelApi.initiateRefundOrder(parmas).then((res) => {
          initRefundRef.value.closeDialogLoading();
          if (res.Code === 200) {
            ElMessage.success("操作成功");
            getDetails();
            initRefundRef.value.closeDialog();
            emit("submit");
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 导出模板
       * 
       * **/
      function downloadTemp() {
        HotelApi.exportReservation().then(() => {
          const link = document.createElement("a");
          link.download = "游客数据模板";
          link.href = `${baseUrl}/hotel/order/export_reservation.api`;
          link.click();
          URL.revokeObjectURL(link.href);
        });
      }
      const fileList = ref([]);  // 上传文件
      const upload = ref(null);
      /**
       * 
       * 批量导入
       * 
       * **/
      function customUpload(params) {
        const fd = new FormData();
        fd.append("reservation", params.file);
        fd.append("otr_otid", inforDatas.value.ot_id);
        const url = `${baseUrl}/hotel/order/import_reservation.api`;
        const config = {
          transformRequest: [
            function (data) {
              return data;
            },
          ],
          headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
        };
        axios.post(url, fd, config).then(
          (res) => {
            if (res.status == 200) {
              if (res.data.Code == 200) {
                ElMessage.success("导入成功！");
                getDetails();
              } else {
                let msg = res.data.Message ? res.data.Message : "导入失败！";
                ElMessage.error(msg);
              }
            }
          },
          (error) => {
            let msg = error.Message ? error.Message : "导入失败！";
            fileList.value = [];
            ElMessage.error(msg);
          }
        );
      }

      return {
        store,
        menuTokens,
        authData,
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        currentRow,
        getDetails,
        inforDatas,
        touristTableRef,
        touristTableColumns,
        attractiTableRef,
        attractiTableColumns,
        refundTableRef,
        refundTableColumns,
        orderStatusColors,
        openTouristDialog,
        editTouristDialogRef,
        handleEditTourist,
        initRefundRef,
        refundPeopleId,
        handleInitRefund,
        fileList,
        upload,
        customUpload,
        downloadTemp,
      };
    },
  };
</script>

<style lang="scss">
  .assoc-detail-dialog {
    .basic-infor {
      background-color: #F3F4F8;
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;
    }

    .basic-infor-title {
      color: #92979E;
      width: 80px;
      text-align: left;
      margin-bottom: 10px;
    }

    .basic-infor-content {
      color: #262C30;
    }

    .download-operation {
      display: flex;
      justify-content: right;

      .batch-upload {
        text-align: center;
        background-color: #fff;
        border: 1px solid var(--theme-color);
        color: var(--theme-color);
        border-radius: 20px;
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          background-color: var(--theme-color);
          color: #fff;
        }

        .el-upload {
          padding: 2px 6px;
          width: 100%;
        }
      }
    }

    .ptb-16 {
      padding: 2px 6px;
    }

  }
</style>