<!-- 酒店管理-订单管理-套票订单-打印 -->

<template>
  <div class="print-order-dialog">
    <w-dialog ref="dialogRef" title="打印纸质票据" width="60%" top="5vh" :hideFooter="true">
      <div v-if="printData && printData.length" class="print-action">
        <div @click="handlePrint(1)" class="preview-print">小票打印</div>
      </div>

      <el-scrollbar height="560px" v-if="printData && printData.length">
        <div style="display: flex; flex-wrap: wrap; justify-content: space-around">
          <div v-for="(el, index) in printData" :key="index" class="print-item" style="
              width: 300px;
              border: 1px solid #eaeaea;
              padding: 10px 10px 20px;
            " :id="'ticket' + index">
            <div :id="'ticketInfo' + index">
              <div style="text-align: center">
                <div style="font-weight: bold; font-size: 14px">
                  <p>{{ el.title }}</p>
                  <p>{{ el.type }}</p>
                </div>
                <div style="margin: 8px 0 14px 0; font-size: 12px">
                  打印时间：{{ el.print_time }}
                </div>
              </div>
              <div>
                <div style="margin-bottom: 6px; font-size: 12px">
                  门票名称：{{ el.ticket_name }}
                </div>
                <div style="margin-bottom: 6px; font-size: 12px">
                  通行凭证：{{ el.paper_sn }}
                </div>
                <div style="margin-bottom: 6px; font-size: 12px">
                  游客姓名：{{ el.real_name }}
                </div>
                <div style="margin-bottom: 6px; font-size: 12px">
                  使用期限：{{ el.start_date }}至{{ el.end_date }}
                </div>
                <div style="margin-bottom: 6px; font-size: 12px">
                  销售日期：{{ el.sale_date }}
                </div>
              </div>
              <div style="margin-top: 14px">
                <div v-for="(el2, index2) in el.bind_list" :key="index2" style="margin-bottom: 6px; font-size: 12px">
                  {{ el2.name
                  }}<span>{{
                    el2.total_num ? "  X" + el2.total_num : ""
                  }}</span>
                </div>
              </div>
            </div>
            <div style="margin: 20px 0 30px 0; text-align: center" :id="'code' + index">
              <QRCode :value="el.qrcode_content" :size="110" lever="H"></QRCode>
              <img src="" alt="" style="display: none" />
            </div>
            <div style="
                border-bottom: 1px dashed #bbb;
                position: relative;
                margin-top: 10px;
              ">
              <div style="
                  position: absolute;
                  background-color: #fff;
                  width: 100px;
                  left: 50%;
                  margin-left: -50px;
                  text-align: center;
                  top: -10px;
                  z-index: 2;
                  font-size: 12px;
                ">
                本次打印结束
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div v-if="!printData.length">
        <el-empty description="暂无可打印内容" />
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { HotelApi, } from "@/plugins/api.js";
  import { getLodop } from "@/utils/LodopFuncs.js";
  import QRCode from "qrcode.vue";
  import { ElMessage } from "element-plus";

  export default {
    components: { QRCode },
    setup() {
      const dialogRef = ref(null);
      const printData = ref([]);
      function openDialog(orderSn) {
        HotelApi.printPaperOrder({ sn: orderSn }).then((res) => {
          if (res.Code === 200) {
            printData.value = res.Data && res.Data.length ? res.Data : [];
            dialogRef.value.show();
          } else {
            let msg = res.Message ? res.Message : "获取打印信息失败！";
            ElMessage.error(msg);
          }
        });
      }
      function handlePrint(type) {
        let LODOP = getLodop(); //调用getLodop获取LODOP对象
        printData.value.length &&
          printData.value.forEach((el, index) => {
            const codeEl = document.getElementById(`code${index}`);
            let canvasEl = codeEl.getElementsByTagName(`canvas`)[0];
            let imgEl = codeEl.getElementsByTagName("img")[0];
            canvasEl.style.display = "none";
            imgEl.src = canvasEl.toDataURL("image/png");
            imgEl.style.display = "";
            LODOP.ADD_PRINT_HTM(
              20,
              20,
              "90%",
              "50%",
              document.getElementById(`ticket${index}`).innerHTML
            );
            LODOP.SET_PRINT_PAGESIZE(3, 720, 45, "");
            LODOP.NewPage();
          });
        type == 1 && LODOP.PREVIEW();
        type == 2 && LODOP.PRINT();
      }
      function closeDialog() {
        dialogRef.value.close();
      }
      function closeDialogLoading() {
        dialogRef.value.isLoading = false;
      }

      return {
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        handlePrint,
        printData,
      };
    },
  };
</script>

<style lang="scss">
  .print-order-dialog {
    .print-icon {
      text-align: right;

      .border-icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 4px;

        cursor: pointer;
        background-color: var(--search-uncheck-bg-color);

        i {
          color: var(--text-third-color);
        }

        &:hover {
          background-color: var(--search-bg-color);

          i {
            color: var(--theme-color) !important;
          }
        }
      }
    }

    .print-action {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      >div {
        padding: 6px 10px;
        border: none;
        border-radius: 10px;
        outline: none;
        background-color: #09f;
        cursor: pointer;
        color: #fff;
        display: flex;
        align-items: center;

        i {
          margin-right: 4px;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      .preview-print {
        margin-right: 10px;
        background-color: #3c9;
      }
    }

    .el-empty {
      padding-bottom: 70px;
    }
  }
</style>